<template>
  <div class="not-found-warp">
    <img src="/static/images/frame/no_auth.svg" alt="暂无权限" srcset="" @click="goLogin">
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  methods: {
    goLogin () {
      this.$store.commit('user/USER_INFO_LOGINOUT')
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="less" scoped>
.not-found-warp {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 70%;
    height: 70%;
    cursor: pointer;
  }
}
</style>
